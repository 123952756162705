<template>
  <div class="container error">
    <transition appear name="slide-fade">
      <Block id="error">
        <div class="message">
          Access denied.
        </div>
        <div class="actions">
          <Btn class="inv" to="Index">
            Grįžti į pagrindinį
          </Btn>
        </div>
      </Block>
    </transition>
  </div>
</template>

<script>
import Errors from '../mixins/Errors'

export default {
  mixins: [Errors],
}
</script>
